import React from "react";
import "./EmsPage.css";
import emsimage from "../Assist/aboutassist/emslan.png";
import { Helmet } from "react-helmet";

const EmsPage = () => {
  return (
    <div className="ems-section">
      {/* seo on emspage */}
      <Helmet>
        <title>Ardra AI - Energy Management System</title>
        <meta
          name="description"
          content="Optimize energy usage and reduce costs with Ardra AI's advanced Energy Management System (EMS). Featuring real-time monitoring, AI-driven insights, and comprehensive control for efficient energy management."
        />
        <meta
          name="keywords"
          content="Energy Management System, EMS, Ardra AI, Energy Optimization, Real-time Monitoring, AI-driven Insights, Energy Analytics"
        />
        <meta name="robots" content="index,follow" />
        <meta
          property="og:title"
          content="Ardra AI - Energy Management System"
        />
        <meta
          property="og:description"
          content="Discover Ardra AI's state-of-the-art Energy Management System (EMS) designed to optimize energy consumption, enhance operational efficiency, and reduce costs."
        />
        <meta property="og:image" content={emsimage} />
        <meta property="og:url" content="https://ardra.ai/emsPage" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="ems-intro">
        <h2 className="ems-title">ardraEMS : Energy Management System</h2>
        <h2 className="probe-subtitle">
          Redefine Energy Efficiency with Cutting-Edge AI Solutions
        </h2>
        <p className="ems-description">
          Ardra AI offers a state-of-the-art energy management system (EMS)
          designed to optimize energy usage, enhance operational efficiency, and
          reduce costs for your facility. By integrating advanced data
          analytics, real-time monitoring, and AI-driven insights, Ardra AI
          provides a holistic solution to manage and optimize your energy
          consumption effectively.
        </p>
      </div>
      {/* new added section */}

      {/* new Added section end */}
      <div className="ems-features">
        <div className="ems-feature1">
          <div className="ems-icon ems-icon-monitor">
            <i className="fas fa-desktop"></i>
          </div>
          <div className="ems-feature-text">
            <h3>Comprehensive Control and Monitoring</h3>
            <p>
              Track energy usage, monitor assets, and control HVAC systems and
              environmental conditions.
            </p>
          </div>
        </div>
        <div className="ems-feature2">
          <div className="ems-icon ems-icon-data">
            <i className="fas fa-database"></i>
          </div>
          <div className="ems-feature-text">
            <h3>Real-Time Data and Analytics</h3>
            <p>
              View real-time data, automate operations, and receive breakdown
              alerts.
            </p>
          </div>
        </div>
        <div className="ems-feature3">
          <div className="ems-icon ems-icon-analytics">
            <i className="fas fa-chart-bar"></i>
          </div>
          <div className="ems-feature-text">
            <h3>Detailed Reporting and Insights</h3>
            <p>
              Generate custom reports, visualize data trends, and leverage
              predictive analytics.
            </p>
          </div>
        </div>
      </div>
      <div className="ems-about">
        <div className="ems-about-image">
          <img src={emsimage} alt="Ardra EMS" className="ems-image" />
        </div>
        <div className="ems-about-content">
          <h3>Why Choose Ardra EMS?</h3>
          <p>
            Ardra EMS is designed to empower businesses with actionable insights
            and precise control over energy consumption. Our cutting-edge
            technology ensures that you not only save on energy costs but also
            contribute to a greener planet by reducing your carbon footprint.
            Whether it's real-time monitoring, automated operations, or
            predictive analytics, Ardra EMS stands as the ultimate tool for
            efficient energy management.
          </p>
        </div>
      </div>
      <div className="ems-demo">
        <h3>Experience the Future of Energy Management with Ardra AI</h3>
        <p>
          Book a demo now with our energy expert and Kick high energy costs to
          the curb.
        </p>
        <a
          href="https://calendly.com/pranjal-2diz/ardra-ai-socials"
          target="_blank"
          rel="noopener noreferrer"
          className="demo-button"
        >
          Book a Demo
        </a>
      </div>
    </div>
  );
};

export default EmsPage;
