import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import blog1 from "../../../Assist/Blogs/blog1.jpeg";
import BlogData from "../BlogData";
import "../BlogPage.css";
import ShareBlog from "../share_blog/share_blog";

const ImprovingEnergyEfficiency = () => {
  const { title } = useParams();
  const category = "Case-study";
  const readTime = "2 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    { id: "section1", title: "The Challenge" },
    { id: "section2", title: "The Solution" },
    { id: "section3", title: "Key Actions Taken" },
    { id: "section4", title: "The Results" },
    { id: "section5", title: "Conclusion" },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [4, 2, 6].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img src={blog1} alt="Future of Energy Management" />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{category}</p>
          <h2>
            Case Study: How Ardra AI Enhanced Energy Efficiency for a
            Large-Scale Manufacturing Plant
          </h2>
          <p className="main-p">
            This case study focuses on how Ardra AI's advanced energy management
            system helped a large-scale manufacturing plant optimize its energy
            consumption and significantly reduce operational costs.
          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <h3>The Challenge</h3>
              <p>
                The manufacturing plant faced high energy costs due to
                inconsistent energy usage patterns, equipment inefficiencies,
                and the lack of real-time monitoring. With fluctuating energy
                demands and outdated systems, the plant struggled to identify
                the root causes of energy wastage.
              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>The Solution</h3>

              <p>
                Ardra AI implemented its AI-driven energy management solution,
                incorporating real-time monitoring through IoT sensors,
                predictive analytics for equipment maintenance, and dynamic load
                management. The solution also integrated with existing systems
                to provide seamless data flow and actionable insights.
              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <h3>Key Actions Taken</h3>
              <br />
              <p>
                <b>1. Predictive Maintenance: </b>
                The system detected potential equipment failures in advance,
                preventing costly breakdowns and reducing energy spikes.
              </p>
              <p>
                <b>2. Dynamic Energy Optimization: </b>
                The AI algorithms adjusted energy loads based on real-time data,
                optimizing energy use during peak and off-peak hours.
              </p>
              <p>
                <b>3. Process Streamlining: </b>
                Recommendations were provided to improve HVAC and lighting
                systems, resulting in more efficient operations.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>The Results</h3>
              <p>
                <b>● 30% Reduction in Energy Costs: </b>
                Achieved within the first six months.
              </p>
              <p>
                <b>● 40% Decrease in Equipment Downtime: </b>
                Due to predictive maintenance.
              </p>
              <p>
                <b>● Significant Carbon Footprint Reduction: </b>
                Aligning with the company’s sustainability goals.
              </p>
            </div>
            <div
              id="section5"
              ref={el => (sections.current[4] = el)}
              className="content-section"
            >
              <h3>Conclusion</h3>
              <p>
                This case study demonstrates how Ardra AI’s innovative
                technology transformed the manufacturing plant’s energy
                management, leading to substantial cost savings, improved
                operational efficiency, and enhanced sustainability.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ShareBlog/>

      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">{item.category}</p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImprovingEnergyEfficiency;
