import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import SustainabilityGoalsmain from "../../../Assist/Blogs/Sustainability-Goals-main.png";
import SustainabilityGoalsinner from "../../../Assist/Blogs/Sustainability-Goals-inner.webp";
import BlogData from "../BlogData";
import "../BlogPage.css";
import ShareBlog from "../share_blog/share_blog";

const SustainabilityGoals = () => {
  const { title } = useParams();
  const category = "Informative Article";
  const readTime = "3 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    { id: "section1", title: "Understanding Smart Energy Management" },
    { id: "section2", title: "Key Components of Smart Energy Management" },
    { id: "section3", title: "Ardra AI: Leading the Charge Toward Net Zero" },
    { id: "section4", title: "The Path to Net Zero: A Comprehensive Approach" },
    { id: "section5", title: "Conclusion: Take Action Toward Sustainability" },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [4, 2, 6].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img src={SustainabilityGoalsmain} alt=" Sustainability Goals" />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{category}</p>
          <h2>
            Sustainability Goals: How Smart Energy Management Can Help Achieve
            Net Zero
          </h2>
          <p className="main-p">
            Achieving net-zero emissions is a pivotal goal for organizations
            across the globe as they strive for sustainability and environmental
            stewardship. Smart energy management is central to this endeavor,
            optimizing energy usage, minimizing waste, and integrating renewable
            energy sources. This article explores how smart energy management,
            particularly through solutions like Ardra AI, can help organizations
            reach their net-zero targets.
          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <h3>Understanding Smart Energy Management</h3>
              <p>
                Smart energy management refers to the strategic use of advanced
                technologies to monitor, analyze, and optimize energy
                consumption. By leveraging tools such as Artificial Intelligence
                (AI), the Internet of Things (IoT), and predictive analytics,
                businesses can gain real-time insights into their energy usage
                patterns. This not only helps in identifying inefficiencies but
                also enables organizations to implement energy-saving measures
                that significantly reduce their carbon footprint.
              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>Key Components of Smart Energy Management</h3>
              <br />
              <h4>1. Real-Time Monitoring </h4>
              <p>
                Smart energy management systems continuously track energy
                consumption, providing organizations with immediate feedback on
                their energy use. This allows for quick identification of
                inefficiencies and waste.
              </p>
              <h4>2. Predictive Analytics</h4>
              <p>
                By analyzing historical data, predictive analytics can forecast
                future energy needs, helping organizations adjust their
                operations proactively to optimize energy efficiency.
              </p>
              <h4>3. Integration with Renewable Energy</h4>
              <p>
                Smart systems can seamlessly integrate renewable energy sources,
                such as solar and wind power, balancing supply and demand to
                ensure a steady flow of green energy.
              </p>
              <h4>4. Automated Controls</h4>
              <p>
                Automation allows for real-time adjustments to energy
                consumption based on data insights, ensuring optimal performance
                without compromising productivity.
              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <img
                src={SustainabilityGoalsinner}
                alt=" Sustainability Goals"
                className="innerimage"
              />
              <br />
              <br />
              <br />
              <h3>Ardra AI: Leading the Charge Toward Net Zero</h3>
              <p>
                Ardra AI is at the forefront of the smart energy management
                revolution, providing AI-driven solutions that empower
                businesses to optimize their energy management practices. Here’s
                how Ardra AI contributes to achieving net-zero emissions:
              </p>
              <h4>1. AI-Driven Insights</h4>
              <p>
                Ardra AI utilizes advanced machine learning algorithms to
                analyze vast datasets, enabling organizations to forecast energy
                needs accurately. This predictive capability allows businesses
                to adjust operations dynamically, ensuring optimal energy
                efficiency.
              </p>
              <h4>2. Enhanced Operational Efficiency</h4>
              <p>
                With features like predictive maintenance and automated
                controls, Ardra AI helps companies reduce energy consumption and
                emissions. By minimizing downtime and optimizing equipment
                performance, businesses can achieve significant cost savings
                while contributing to sustainability goals.
              </p>
              <h4>3. Seamless Integration</h4>
              <p>
                Ardra AI can easily integrate with existing building management
                systems (BMS), allowing organizations to enhance their energy
                management capabilities without overhauling their current
                infrastructure. This flexibility is crucial for businesses
                looking to transition to smarter energy practices.
              </p>
              <h4>4. Comprehensive Reporting</h4>
              <p>
                Ardra AI provides detailed reports on energy usage and
                emissions, helping organizations quantify their progress toward
                net-zero goals. This transparency is vital for meeting
                regulatory requirements and demonstrating commitment to
                sustainability.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>The Path to Net Zero: A Comprehensive Approach</h3>
              <p>
                Achieving net-zero emissions requires a multifaceted approach to
                energy management. Organizations should consider the following
                strategies:
              </p>
              <p>
                <b>● Conduct Energy Audits:</b> Regular audits help identify
                areas for improvement and set clear sustainability goals.
              </p>
              <p>
                <b>● Implement Smart Energy Solutions:</b> Leveraging
                technologies like Ardra AI ensures that energy management
                practices are data-driven and efficient.
              </p>
              <p>
                <b>● Engage Employees:</b> Foster a culture of sustainability
                within the organization by educating employees about energy
                conservation practices.
              </p>
              <p>
                <b>● Invest in Renewable Energy:</b> Transitioning to renewable
                energy sources is essential for reducing overall emissions and
                achieving net-zero targets.
              </p>
            </div>

            <div
              id="section5"
              ref={el => (sections.current[4] = el)}
              className="content-section"
            >
              <h3>Conclusion: Take Action Toward Sustainability</h3>
              <p>
                Smart energy management is not just a trend; it’s a necessity
                for organizations aiming to achieve net-zero emissions. By
                integrating solutions like Ardra AI into their energy management
                strategies, businesses can optimize their energy usage, reduce
                waste, and contribute to a greener future.
              </p>
              <p>
                Are you ready to take the next step in your sustainability
                journey? Book a demo with Ardra AI today to discover how our
                innovative solutions can help you achieve your net-zero goals
                and transform your energy management practices. Together, let’s
                create a sustainable future for generations to come.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ShareBlog/>
      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
               <p className="blog-item-category">
                {item.type ? item.type : item.category}
              </p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SustainabilityGoals;
