import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import blog1 from "../../../Assist/Blogs/blog1.jpeg";
import BlogData from "../BlogData";
import "../BlogPage.css";
import ShareBlog from "../share_blog/share_blog";

const HowdoesAIdrivenpredictivemaintenancework = () => {
  const { title } = useParams();
  const type = "Informative Article";
  const readTime = "3 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    { id: "section1", title: "1. Data Collection and Integration" },
    { id: "section2", title: "2. Data Preprocessing" },
    { id: "section3", title: "3. AI Analysis and Machine Learning" },
    { id: "section4", title: "4. Predictive Modeling" },
    { id: "section5", title: "5. Automated Decision-Making" },
    { id: "section6", title: "6. Continuous Learning and Improvement" },
    { id: "section7", title: "7. User Interface and Reporting" },
    { id: "section8", title: "Conclusion" },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [4, 2, 6].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 120, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img src={blog1} alt="Future of Energy Management" />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{type}</p>
          <h2>
            How does AI-driven predictive maintenance work in industrial
            settings
          </h2>
          <p className="main-p">
            Imagine walking into a bustling manufacturing plant where machines
            hum in harmony, producing goods at an impressive pace. Suddenly, a
            loud clank echoes through the facility, and production grinds to a
            halt. A critical piece of equipment has failed, leading to costly
            downtime and frantic scrambling for a solution. Now, picture a
            different scenario: the same plant, but this time, an invisible
            guardian is at work—an AI-driven predictive maintenance system that
            foresees potential failures before they disrupt operations. This
            technology not only keeps the machines running smoothly but also
            transforms the way industries approach maintenance.
          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <p>
                In today’s fast-paced industrial landscape, the stakes are
                higher than ever. Equipment failures can lead to significant
                financial losses, safety hazards, and reputational damage. Enter
                AI-driven predictive maintenance—a game-changing strategy that
                leverages advanced technologies to anticipate problems and
                optimize maintenance schedules. This proactive approach not only
                enhances operational efficiency but also revolutionizes the way
                organizations manage their assets. Here’s how it works:
              </p>
              <h3>1. Data Collection and Integration</h3>
              <p>
                At the core of AI-driven predictive maintenance is the Internet
                of Things (IoT), which collects vast amounts of data from
                various sensors and devices installed on machinery. These
                sensors monitor critical parameters such as temperature,
                vibration, noise, and usage patterns in real-time. The data
                collected is then integrated into a centralized system for
                further analysis.
              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>2. Data Preprocessing</h3>
              <p>
                Before the data can be analyzed, it must be preprocessed to
                remove noise and outliers. This step ensures that the data is
                clean and consistent, making it suitable for analysis. Properly
                contextualized data is crucial for AI algorithms to accurately
                assess equipment conditions and predict potential failures.
              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <h3>3. AI Analysis and Machine Learning</h3>
              <p>
                Once the data is preprocessed, AI algorithms, particularly
                machine learning models, come into play. These algorithms
                analyze historical and real-time data to identify patterns and
                trends that may indicate impending failures. For instance,
                machine learning can assess how specific conditions correlate
                with past equipment failures, allowing the system to predict
                when a similar failure might occur in the future.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>4. Predictive Modeling</h3>
              <p>
                AI models continuously evaluate multiple variables reflecting an
                asset's current status. By comparing real-time data against
                historical performance metrics, the system can forecast
                potential equipment breakdowns. This predictive capability
                allows maintenance teams to schedule servicing only when
                necessary, rather than adhering to a fixed maintenance schedule.
              </p>
            </div>

            <div
              id="section5"
              ref={el => (sections.current[4] = el)}
              className="content-section"
            >
              <h3>5. Automated Decision-Making</h3>
              <p>
                Based on the insights generated, the predictive maintenance
                system can automate decision-making processes. For example, if
                the AI detects that a machine's temperature exceeds a critical
                threshold, it can trigger an alert for preventive maintenance.
                This automation not only streamlines operations but also helps
                in prioritizing maintenance tasks based on urgency and potential
                impact on production.
              </p>
            </div>

            <div
              id="section6"
              ref={el => (sections.current[5] = el)}
              className="content-section"
            >
              <h3>6. Continuous Learning and Improvement</h3>
              <p>
                AI systems improve over time as they collect more data. The
                algorithms can learn from new inputs, refining their predictive
                accuracy and adapting to changes in equipment performance or
                operational conditions. This continuous learning process ensures
                that the predictive maintenance strategy evolves alongside the
                equipment and operational environment.
              </p>
            </div>

            <div
              id="section7"
              ref={el => (sections.current[6] = el)}
              className="content-section"
            >
              <h3>7. User Interface and Reporting</h3>
              <p>
                Finally, the results of AI analysis are presented through
                user-friendly interfaces, such as dashboards and alerts. These
                tools allow operators and maintenance teams to quickly
                understand the state of equipment and make informed decisions
                about maintenance actions. Effective reporting helps in tracking
                performance metrics and justifying maintenance interventions.
              </p>
            </div>

            <div
              id="section8"
              ref={el => (sections.current[7] = el)}
              className="content-section"
            >
              <h3>Conclusion</h3>
              <p>
                AI-driven predictive maintenance represents a significant
                advancement in industrial operations, shifting the focus from
                reactive to proactive maintenance strategies. By harnessing the
                power of IoT and AI, organizations can reduce downtime, extend
                equipment lifespan, and optimize maintenance costs. This
                approach not only enhances operational efficiency but also
                contributes to a safer and more sustainable industrial
                environment. As industries continue to adopt these technologies,
                the potential for increased productivity and reduced operational
                risks will only grow.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ShareBlog/>
      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">
                {item.type ? item.type : item.category}
              </p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowdoesAIdrivenpredictivemaintenancework;
