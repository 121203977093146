import React, { useState } from "react";
import axios from "axios";
import "./SubscribeForm.css";

const SubscribeForm = () => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubscribeClick = () => {
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
    setEmail("");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await axios.post("https://ardra-a-ibackend.vercel.app/api/blog/subscribe", { email });
      setFormVisible(false); // Hide form after successful submission
      setEmail("");
    } catch (error) {
      console.log("Error", error); 
    }
  };

  return (
    <div className="subscribe-container">
      <button onClick={handleSubscribeClick} className="subscribe-button">
        <i className="fa-solid fa-bell fa-lg"></i>
      </button>

      {/* Popup Overlay */}
      {isFormVisible && (
        <div className="subscribe_popup-overlay">
          <div className="subscribe_popup-content">
            <button className="close-button" onClick={handleCloseForm}>
              &times;
            </button>
            <h2 className="subscribe_title">Subscribe</h2>
            <form onSubmit={handleSubmit} className="subscribe-form">
              <input
                type="email"
                className="subscribe-input"
                placeholder="Enter your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscribeForm;
