import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import Lottie from 'react-lottie';
import ardragptassist from '../../Assist/landingfeatureassist/ardraGPTanimation.json';
import './Banner.css';

const Banner = ({ setIsBannerOpen }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleNavigate = () => {
    window.location.href = 'https://gpt.ardra.ai/';
    setIsOpen(false);
    setIsBannerOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsBannerOpen(false); // Update the Home_Header state
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ardragptassist,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      {isOpen && (
        <div className="overlay_banner slide-down">
          <div className="banner-container">
            <button onClick={handleClose} className="close-button_banner">
              &times;
            </button>

            <div className="content_banner">
              <h1 className="title_banner">Transform Your Efficiency</h1>
              <p className="description_banner">
                Experience cutting-edge AI insights with ArdraGPT.
              </p>
              <button onClick={handleNavigate} className="explore-button scale-on-hover">
                <span className="button-text">
                  Explore ArdraGPT
                  <span className="arrow-icon slide-right">
                    <FaArrowRight />
                  </span>
                </span>
              </button>
            </div>

            <div className="animation-container">
              <Lottie options={defaultOptions} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
