import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import BlogData from "../BlogData";
import "../BlogPage.css";
import TransformingEnergyEfficiencyinner from "../../../Assist/Blogs/Transforming-Energy-Efficiency-inner.jpg";
import TransformingEnergyEfficiencymain from "../../../Assist/Blogs/Transforming-Energy-Efficiency-main.jpg";
import ShareBlog from "../share_blog/share_blog";
import { Helmet } from "react-helmet";
import SubscribeForm from "../SubscribeForm/SubscribeForm";
import LikeBlog from "../Like_blog/Like_blog";
import LikeBlogMain from "../Like_blog/Like_blog_mainPage";

const TransformingEnergyEfficiency = () => {
  const { id } = useParams(); // Get the dynamic ID from the URL
  const selectedBlog = BlogData.find(blog => blog.id === parseInt(id));
  const readTime = "4 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    { id: "section1", title: "Client Profile" },
    { id: "section2", title: "Problem Overview" },
    {
      id: "section3",
      title: "Solution: Implementing Ardra AI for Smart Energy Management",
    },
    { id: "section4", title: "Key Actions Taken" },
    { id: "section5", title: "Results" },
    { id: "section6", title: "Client Testimonial" },
    { id: "section7", title: "Conclusion" },
    {
      id: "section8",
      title: "Interested in optimizing your factory’s energy use?",
    },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [4, 6, 2].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <Helmet>
        <title>
          Case Study - Transforming Energy Efficiency for an Indian Factory
          Owner
        </title>
        <meta
          name="description"
          content="This case study highlights how Ardra AI's solutions were implemented in a smart factory in India to optimize energy efficiency and reduce costs."
        />
        <meta
          name="keywords"
          content="energy management, Ardra AI, factory optimization, energy efficiency, energy savings, smart factories, AI in energy management"
        />
        <meta
          property="og:title"
          content="Case Study: Transforming Energy Efficiency for an Indian Factory"
        />
        <meta
          property="og:description"
          content="Learn how Ardra AI helped an Indian factory owner reduce energy bills by 25% and increase productivity by 15% with AI-based energy management solutions."
        />
        <meta property="og:image" content={TransformingEnergyEfficiencymain} />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="https://ardra.ai/blog/transforming-energy-efficiency"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Case Study: Transforming Energy Efficiency for an Indian Factory"
        />
        <meta
          name="twitter:description"
          content="Discover how AI revolutionized energy management for an Indian factory, reducing energy costs and improving operational efficiency."
        />
        <meta name="twitter:image" content={TransformingEnergyEfficiencymain} />
      </Helmet>

      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img
            src={TransformingEnergyEfficiencymain}
            alt="Transforming Energy Efficiency"
          />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{selectedBlog.category}</p>
          <h2>
            Case Study: Transforming Energy Efficiency for an Indian Factory
            Owner
          </h2>
          <p className="main-p">
            This case study highlights how Ardra AI’s solutions were implemented
            in a modern smart building complex to achieve optimal energy
            efficiency and reduce overall energy costs.
          </p>
          <p className="blog-author">
            By the team at Ardra AI<p className="blog-date"> 24th March 2024</p>
          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <h3>Client Profile:</h3>
              <br />
              <p>
                <b>● Name: </b>Rohit Patel
              </p>
              <p>
                <b>● Age: </b>34
              </p>
              <p>
                <b>● Industry: </b> Small-scale manufacturing factory (packaging
                materials)
              </p>
              <p>
                <b>● Location: </b>Gujarat, India
              </p>
              <p>
                <b>● Challenges: </b> High energy bills, low productivity, and
                operational inefficiencies
              </p>
              <p>
                <b>● Goal: </b> Reduce energy consumption, boost productivity,
                and improve overall factory efficiency
              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>Problem Overview</h3>

              <p>
                Rohit Patel, a young and ambitious factory owner, was grappling
                with skyrocketing energy bills. Despite investing in modern
                machinery, his packaging factory faced constant operational
                inefficiencies, leading to frequent equipment downtime,
                underutilized resources, and high production costs. On top of
                that, Rohit's factory was struggling to meet its sustainability
                goals. With energy costs eating up{" "}
                <b>35% of operational expenses,</b> Rohit knew he needed an
                advanced solution that could optimize energy usage and improve
                factory performance.
              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <img
                src={TransformingEnergyEfficiencyinner}
                alt="Transforming Energy Efficiency"
                className="innerimage"
                style={{ width: "100%", height: "auto" }}
              />
              <br />
              <br />
              <br />
              <h3>
                Solution: Implementing Ardra AI for Smart Energy Management
              </h3>
              <p>
                After a detailed consultation with Ardra AI, Rohit opted to
                integrate <b>ArdraGPT</b>, a conversational AI energy assistant
                designed to help factories like his cut energy costs and
                streamline operations. ArdraGPT is built to analyze energy data
                in real-time and provide actionable insights for optimizing
                energy usage, reducing wastage, and improving overall
                productivity.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>Key Actions Taken</h3>
              <br />
              <p>
                <b>1. Energy Monitoring and Analysis: </b>
                Ardra AI deployed real-time sensors across the factory's
                machines to monitor energy consumption at different stages of
                production. By identifying energy-draining equipment, ArdraGPT
                suggested adjustments in operating times and machine usage.
              </p>
              <p>
                <b>2. Predictive Maintenance: </b>
                The AI agent identified patterns in equipment wear and tear,
                predicting possible malfunctions before they occurred. This
                allowed Rohit to schedule maintenance during off-peak hours,
                reducing unexpected downtime.
              </p>
              <p>
                <b>3. Optimizing Machine Usage: </b>
                ArdraGPT's data analysis revealed that machines were often
                running at inefficient times, leading to excessive energy
                consumption. The AI tool recommended optimized scheduling,
                ensuring machines operated during periods of lower energy
                demand.
              </p>
              <p>
                <b>4. Resource Management: </b>
                ArdraGPT provided insights into material usage and wastage,
                helping Rohit make better-informed decisions about resource
                allocation and minimizing waste.
              </p>
              <p>
                <b>5. Sustainability and Compliance: </b>
                By optimizing the factory's energy consumption and reducing CO2
                emissions, Rohit was able to meet both regulatory requirements
                and his sustainability goals. ArdraGPT provided a pathway to
                greener factory operations.
              </p>
            </div>
            <div
              id="section5"
              ref={el => (sections.current[4] = el)}
              className="content-section"
            >
              <h3>The Results</h3>
              <p>
                <b>● Energy Cost Reduction: </b>
                Rohit's factory saw an immediate{" "}
                <b>25% reduction in energy bills</b> within the first three
                months of implementation.
              </p>
              <p>
                <b>● Increased Productivity: </b>
                With optimized machine schedules and reduced downtime, factory
                productivity improved by <b>15%.</b>
              </p>
              <p>
                <b>● Predictive Maintenance Savings: </b>
                Avoiding unplanned breakdowns saved Rohit ₹5 Lakhs in annual
                maintenance costs.
              </p>
              <p>
                <b>● Sustainability Impact: </b>
                The factory's carbon footprint decreased by 20%, aligning
                Rohit's business with sustainability goals and improving the
                factory's reputation in the market.
              </p>
            </div>
            <div
              id="section6"
              ref={el => (sections.current[5] = el)}
              className="content-section"
            >
              <h3>Client Testimonial</h3>
              <p style={{ fontStyle: "italic" }}>
                "ArdraGPT has been a game-changer for my factory. I used to
                spend a significant portion of my revenue on energy bills, and
                our machines would frequently break down. With ArdraGPT, not
                only have my costs gone down, but my production has also become
                more streamlined. I'm seeing real improvements in efficiency,
                and we’re on track to meet our sustainability targets for the
                first time!" <br />— <b>Rohit Patel</b>, Factory Owner
              </p>
            </div>
            <div
              id="section7"
              ref={el => (sections.current[6] = el)}
              className="content-section"
            >
              <h3>Conclusion</h3>
              <p>
                With Ardra AI, Rohit Patel was able to turn around his factory's
                performance, achieving significant cost savings and operational
                improvements. ArdraGPT's advanced AI-driven insights allowed
                Rohit to take full control of his energy management, enhancing
                productivity while minimizing waste. Rohit’s factory is now
                operating at peak efficiency, showcasing how AI-powered
                solutions can make a tangible difference for small-scale
                manufacturers.
              </p>
              <p>
                If you are facing similar challenges in your factory or
                facility, <b>ArdraGPT</b> can help you transform your energy
                management and improve overall efficiency.
              </p>
            </div>
            <div
              id="section8"
              ref={el => (sections.current[7] = el)}
              className="content-section"
            >
              <h3>Interested in optimizing your factory’s energy use?</h3>
              <p>
                Contact Ardra AI today for a free demo and explore how ArdraGPT
                can save you money and boost productivity.
              </p>
              <br />
              <p>
                [For data privacy reasons name of the owner is kept
                hidden/changed]
              </p>
            </div>
          </div>
        </div>
      </div>
     
      <div className="blog_social_section">
        <div className="blog_social_section_p">
          <p>💬 Join the conversation:</p>
        </div>
        <div className="blog_social_section_content">
          <LikeBlog blogId={id} />
          <ShareBlog />
          <SubscribeForm />
        </div>
      </div>

      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">
                {item.type ? item.type : item.category}<LikeBlogMain blogId={item.id}/>
              </p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransformingEnergyEfficiency;
