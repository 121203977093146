import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import BlogData from "../BlogData";
import HowIoTandAIAreShapingtheFuturemain from"../../../Assist/Blogs/How-IoT-and-AI-Are-Shaping-the-Future-main.png";
import HowIoTandAIAreShapingtheFutureinner from"../../../Assist/Blogs/How-IoT-and-AI-Are-Shaping-the-Future-inner.jpg";
import "../BlogPage.css";
import ShareBlog from "../share_blog/share_blog";

const HowIoTandAIAreShapingtheFuture = () => {
  const { title } = useParams();
  const category = "Informative Article";
  const readTime = "2 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    { id: "section1", title: "The Dawn of a New Era in Energy Management" },
    { id: "section2", title: "IoT: The Foundation for Real-Time Monitoring" },
    { id: "section3", title: "AI: The Brain Behind the Optimization" },
    {
      id: "section4",
      title: "Automated Control: The Key to Significant Savings",
    },
    { id: "section5", title: "Towards a Sustainable Future" },
    { id: "section6", title: "The Future is Bright " },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [4, 2, 6].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img src={HowIoTandAIAreShapingtheFuturemain} alt="How IoT and AI Are Shaping the Future" />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{category}</p>
          <h2>How IoT and AI Are Shaping the Future of Energy Management</h2>
          <p className="main-p">
            Discusses the importance of smart energy management in achieving
            sustainability goals and how Ardra AI can contribute.
          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <h3>The Dawn of a New Era in Energy Management</h3>
              <p>
                The world of energy management is undergoing a transformative
                shift, thanks to the powerful synergy between the Internet of
                Things (IoT) and Artificial Intelligence (AI). This dynamic duo
                is revolutionizing how we monitor, control, and optimize energy
                usage, paving the way for a future that is more efficient,
                sustainable, and responsive to our ever-changing needs.
              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>IoT: The Foundation for Real-Time Monitoring</h3>
              <p>
                IoT technology is the backbone of this energy revolution,
                providing the infrastructure needed for real-time data
                collection. Smart meters, sensors, and connected devices are
                strategically placed throughout buildings, factories, and even
                entire cities, gathering vast amounts of data on energy usage,
                equipment performance, and environmental conditions. This
                granular data offers unprecedented visibility into energy
                consumption patterns, equipment health, and potential
                inefficiencies, laying the groundwork for intelligent
                decision-making.
              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <img
                src={HowIoTandAIAreShapingtheFutureinner}
                alt="How IoT and AI Are Shaping the Future"
                className="innerimage" style={{width:'100%'}}
              />
              <br />
              <br />
              <br />
              <h3>AI: The Brain Behind the Optimization</h3>
              <p>
                While IoT collects the data, AI is the brain that processes and
                extracts actionable insights from it. Machine learning
                algorithms analyze historical and real-time data to predict
                energy demand, detect anomalies, and identify optimization
                opportunities. By leveraging AI's ability to learn and adapt,
                energy management systems can become more efficient, responsive,
                and proactive in their approach.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>Automated Control: The Key to Significant Savings</h3>
              <p>
                The synergy between IoT and AI enables automated control systems
                that dynamically adjust energy-intensive operations based on
                real-time data and predictive models. AI algorithms can optimize
                HVAC systems, lighting, and other energy-consuming equipment,
                ensuring that energy is used efficiently and effectively. This
                results in significant energy savings, reduced operational
                costs, and a smaller carbon footprint.
              </p>
            </div>

            <div
              id="section5"
              ref={el => (sections.current[4] = el)}
              className="content-section"
            >
              <h3>Towards a Sustainable Future</h3>
              <p>
                As the world becomes increasingly conscious of the need for
                sustainability, the integration of IoT and AI in energy
                management is more crucial than ever. These technologies are not
                only enhancing efficiency but also aligning energy systems with
                the global drive towards a greener future. By leveraging the
                power of data and intelligent decision-making, organizations can
                make informed choices about their energy usage, minimizing waste
                and maximizing the use of renewable energy sources.
              </p>
            </div>
            <div
              id="section6"
              ref={el => (sections.current[5] = el)}
              className="content-section"
            >
              <h3>The Future is Bright</h3>
              <p>
                The future of energy management is undoubtedly bright, with IoT
                and AI leading the charge towards a smarter, more adaptive
                energy landscape. As these technologies continue to evolve, we
                can expect to see even more advanced applications, such as
                predictive maintenance, demand response optimization, and
                personalized energy recommendations. The possibilities are
                endless, and the benefits are clear: a future where energy is
                managed efficiently, sustainably, and in harmony with our
                ever-changing needs.
              </p>
              <p>
                In conclusion, the integration of IoT and AI in energy
                management is not just a trend; it is a necessity. By embracing
                these transformative technologies, we can create a future where
                energy is no longer a constraint but a resource that empowers us
                to achieve our goals while preserving the planet we call home.
                The time to act is now, and the rewards are boundless.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ShareBlog/>
      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
               <p className="blog-item-category">
                {item.type ? item.type : item.category}
              </p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowIoTandAIAreShapingtheFuture;
