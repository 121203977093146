import React, { useState } from 'react';
import './GPTBanner.css';

const GPTBanner = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setCursorPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  return (
    <div className="GPTbanner" onMouseMove={handleMouseMove}>
      <div
        className="cursor-circle"
        style={{
          left: `${cursorPosition.x - 10}px`, 
          top: `${cursorPosition.y - 10}px`, 
        }}
      >
         
      </div>
      <p className="GPTbanner-text">
      "Voice-Driven Graphs, Insightfully Efficient"
      <span style={{ backgroundColor: 'transparent' }}>🎙️📊✨</span>
      </p>
    </div>
  );
};
export default GPTBanner;
