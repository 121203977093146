import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../Detail_blog.css";
import blog1 from "../../../Assist/Blogs/blog1.jpeg";
import BlogData from "../BlogData";
import "../BlogPage.css";
import ShareBlog from "../share_blog/share_blog";
import TopTrendsinEnergyManagementmain from "../../../Assist/Blogs/Top-Trends-in-Energy-Management-main.png";
import TopTrendsinEnergyManagementinner from "../../../Assist/Blogs/Top-Trends-in-Energy-Management-inner.jpg";
import SubscribeForm from "../SubscribeForm/SubscribeForm";
import LikeBlog from "../Like_blog/Like_blog";
import LikeBlogMain from "../Like_blog/Like_blog_mainPage";

const TopTrendsinEnergyManagement = () => {
  const { id } = useParams(); // Get the dynamic ID from the URL
  const selectedBlog = BlogData.find(blog => blog.id === parseInt(id));
  const readTime = "4 min read";
  const [activeSection, setActiveSection] = useState("");
  const [isTableOfContentsExpanded, setTableOfContentsExpanded] =
    useState(false);
  const sections = useRef([]);

  const tocSections = [
    { id: "section1", title: "1. AI-Driven Energy Optimization" },
    { id: "section2", title: "2. Integration of Renewable Energy Sources" },
    { id: "section3", title: "3. Advanced IoT and Sensor Networks" },
    { id: "section4", title: "4. Predictive Maintenance" },
    { id: "section5", title: "5. Energy-as-a-Service (EaaS) Models" },
    {
      id: "section6",
      title: "Conclusion: Embracing the Future of Energy Management",
    },
  ];

  // Filter for only 3 items by ID
  const selecteditems = BlogData.filter(item => [4, 15, 6].includes(item.id));

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = tocSections.map(section => {
        const el = sections.current.find(ref => ref?.id === section.id);
        return {
          id: section.id,
          offsetTop: el ? el.offsetTop : 0,
          offsetHeight: el ? el.offsetHeight : 0,
        };
      });

      const currentPosition = window.scrollY + 150; // Adjust for sticky navbar height

      for (const section of sectionOffsets) {
        if (
          currentPosition >= section.offsetTop &&
          currentPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tocSections]);

  const handleClick = id => {
    setActiveSection(id); // Change the active section on click
    const sectionElement = sections.current.find(ref => ref?.id === id);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 130, // Adjust for sticky navbar height
        behavior: "smooth",
      });
    }
  };

  const toggleTableOfContents = () => {
    setTableOfContentsExpanded(!isTableOfContentsExpanded);
  };

  return (
    <div className="future-energy-management">
      <div className="blog-intro-section">
        <div className="blog-intro-image">
          <img
            src={TopTrendsinEnergyManagementmain}
            alt="Future of Energy Management"
          />
        </div>
        <div className="blog-intro-text">
          <p className="blog-item-category">{selectedBlog.type}</p>
          <h2>Top Trends in Energy Management for 2024</h2>
          <p className="main-p">
            As we usher in 2024, the landscape of energy management is more
            dynamic than ever, driven by innovative technologies and a
            commitment to sustainability. With energy prices fluctuating and
            environmental concerns intensifying, organizations are seeking
            smarter solutions to optimize their energy usage. The trends shaping
            the industry this year not only promise enhanced efficiency but also
            align closely with global sustainability goals. Here’s a closer look
            at the top trends in energy management for 2024 that are set to
            redefine how we consume energy.
          </p>
          <p className="blog-author">
            <b>Author: </b>Pranjal Yadav
            <p className="blog-date"> 14th October 2024</p>
          </p>
        </div>
      </div>

      <div className="content-container">
        {/* Table of Contents */}
        <aside
          className={`table-of-contents ${
            isTableOfContentsExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="toc-toggle" onClick={toggleTableOfContents}>
            <span>TABLE OF CONTENTS</span>
            <span
              className={`chevron ${
                isTableOfContentsExpanded ? "expanded" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  isTableOfContentsExpanded
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                }`}
              ></i>
            </span>
          </div>
          <ul>
            {tocSections.map(section => (
              <li
                key={section.id}
                className={activeSection === section.id ? "active" : ""}
              >
                <a
                  href={`#${section.id}`}
                  onClick={e => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(section.id);
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-header">
            <div className="read-time">
              <i className="fa-regular fa-clock"></i> {readTime}
            </div>
            <div className="social-icons">
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-lg fa-fade"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in fa-xl fa-fade"></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram fa-xl fa-fade"></i>
              </a>
            </div>
          </div>

          {/* Content Sections */}
          <div className="content-sections">
            <div
              id="section1"
              ref={el => (sections.current[0] = el)}
              className="content-section"
            >
              <h3>1. AI-Driven Energy Optimization</h3>
              <p>
                Artificial Intelligence (AI) is revolutionizing energy
                management by enabling real-time data analysis and
                decision-making. AI algorithms can analyze vast datasets to
                predict energy demand and optimize equipment usage, leading to
                significant waste reduction. According to the International
                Energy Agency, AI could help reduce energy consumption by up to
                10% across various sectors. For instance, AI-powered systems can
                adjust heating, ventilation, and air conditioning (HVAC)
                settings based on occupancy patterns, ensuring energy is used
                efficiently without compromising comfort.
              </p>
            </div>

            <div
              id="section2"
              ref={el => (sections.current[1] = el)}
              className="content-section"
            >
              <h3>2. Integration of Renewable Energy Sources</h3>
              <p>
                As sustainability becomes a priority, organizations are
                increasingly integrating renewable energy sources like solar and
                wind into their energy portfolios. In 2023, renewables accounted
                for about 29% of global electricity generation, and this trend
                is expected to accelerate. Smart grids and energy storage
                solutions are essential for managing the variability of
                renewable energy, ensuring a reliable power supply. By adopting
                these technologies, businesses not only reduce their carbon
                footprint but also enhance their energy independence.
              </p>
            </div>

            <div
              id="section3"
              ref={el => (sections.current[2] = el)}
              className="content-section"
            >
              <img
                src={TopTrendsinEnergyManagementinner}
                alt="The Trends in Energy Management"
                className="innerimage"
                style={{ width: "100%", height: "auto" }}
              />
              <br />
              <br />
              <br />
              <h3>3. Advanced IoT and Sensor Networks</h3>
              <p>
                The Internet of Things (IoT) is playing a crucial role in energy
                management by enabling real-time monitoring of energy
                consumption and equipment performance. The IoT in the energy
                market is projected to reach $38.9 billion by 2027, growing at a
                CAGR of 24.5%. Smart meters and sensors provide detailed
                insights into energy usage, allowing organizations to identify
                inefficiencies and implement corrective actions swiftly. For
                example, IoT devices can track energy consumption patterns and
                alert managers to anomalies, facilitating timely interventions
                that enhance overall efficiency.
              </p>
            </div>

            <div
              id="section4"
              ref={el => (sections.current[3] = el)}
              className="content-section"
            >
              <h3>4. Predictive Maintenance</h3>
              <p>
                Predictive maintenance, powered by machine learning, is becoming
                a vital component of energy management strategies. By analyzing
                sensor data, AI can forecast equipment failures and
                inefficiencies before they occur, potentially reducing downtime
                by up to 50%. Industries that implement predictive maintenance
                report average savings of 8-12% on maintenance costs. This
                proactive approach not only conserves resources but also lowers
                energy expenses, making it a valuable strategy for organizations
                looking to optimize their operations.
              </p>
            </div>

            <div
              id="section5"
              ref={el => (sections.current[4] = el)}
              className="content-section"
            >
              <h3>5. Energy-as-a-Service (EaaS) Models</h3>
              <p>
                The Energy-as-a-Service (EaaS) model is gaining traction as a
                flexible solution that allows businesses to outsource their
                energy management needs. This model provides access to advanced
                technologies and expertise without the burden of significant
                upfront investments. According to Navigant Research, the EaaS
                market is expected to grow to $60 billion by 2025. By leveraging
                EaaS, organizations can focus on their core business while
                benefiting from enhanced energy efficiency and sustainability.
              </p>
            </div>
            <div
              id="section6"
              ref={el => (sections.current[5] = el)}
              className="content-section"
            >
              <h3>Conclusion: Embracing the Future of Energy Management</h3>
              <p>
                The trends in energy management for 2024 highlight a future
                where energy consumption is not only more intelligent but also
                deeply aligned with sustainability goals. The integration of AI,
                IoT, and renewable energy sources is paving the way for a
                smarter, greener energy landscape. Organizations that embrace
                these innovations will enhance their operational efficiency and
                contribute to a more sustainable world.
              </p>
              <p>
                For more insights into how these trends can impact your energy
                management strategy, visit{" "}
                <a href="https://ardra.ai/" target="_blank">
                  ardra AI
                </a>{" "}
                and explore our innovative solutions. Are you ready to transform
                your energy management approach? Let’s work together to create a
                brighter, more sustainable future!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="blog_social_section">
        <div className="blog_social_section_p">
          <p>💬 Join the conversation:</p>
        </div>
        <div className="blog_social_section_content">
          <LikeBlog blogId={id} />
          <ShareBlog />
          <SubscribeForm />
        </div>
      </div>

      <div className="all-blogs">
        <h3 className="blog-detail-heading">Keep reading</h3>
        <div className="blog-items">
          {selecteditems.map(item => (
            <div key={item.id} className="blog-item">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className="blog-item-image"
                />
              )}
              <p className="blog-item-category">
                {item.type ? item.type : item.category}<LikeBlogMain blogId={item.id}/>
              </p>
              <a href={item.link} className="blog-item-link">
                <h3 className="blog-item-title">{item.title}</h3>
              </a>
              <p className="blog-item-summary">{item.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopTrendsinEnergyManagement;
