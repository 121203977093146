import React from "react";
import "./ProbePage.css";
import Lottie from "react-lottie";
import animationData from "../Assist/landingfeatureassist/sign.json";
import probeimage from "../Assist/probeassist/probe2.png";
import { Helmet } from "react-helmet";

const iconStyle = {
  fontSize: "2rem",
  color: "#fff",
  borderRadius: "50%",
  padding: "20px",
  marginBottom: "15px",
};

const features = [
  {
    icon: (
      <i
        className="fa-solid fa-gears"
        style={{ ...iconStyle, backgroundColor: "#7ac27a" }}
      ></i>
    ),
    title: "Automated Data Processing",
    description:
      "Our system automates the ingestion and processing of data from various sources, ensuring timely and accurate data availability for your analysis needs.",
    backgroundColor: "#D1E6D1",
  },
  {
    icon: (
      <i
        className="fa-solid fa-chart-pie"
        style={{ ...iconStyle, backgroundColor: "#5e74d3" }}
      ></i>
    ),
    title: "In-Depth Analytics",
    description:
      "Gain deep insights into your energy usage with advanced analytics that help identify trends, anomalies, and opportunities for optimization.",
    backgroundColor: "#8FA3FF",
  },
  {
    icon: (
      <i
        className="fa-solid fa-network-wired"
        style={{ ...iconStyle, backgroundColor: "#9cb6d0" }}
      ></i>
    ),
    title: "Seamless Integration",
    description:
      "Integrate our system effortlessly with your existing infrastructure to create a cohesive and efficient data management environment.",
    backgroundColor: "#D8E2EC",
  },
  {
    icon: (
      <i
        className="fa-solid fa-shield-halved"
        style={{ ...iconStyle, backgroundColor: "#b569c6" }}
      ></i>
    ),
    title: "Enhanced Security",
    description:
      "Protect your sensitive data with our robust security protocols, ensuring data integrity and compliance with industry standards.",
    backgroundColor: "#C6A3CE",
  },
  {
    icon: (
      <i
        className="fa-solid fa-bolt"
        style={{ ...iconStyle, backgroundColor: "#9cb6d0" }}
      ></i>
    ),
    title: "Real-Time Monitoring",
    description:
      "Monitor your energy data in real-time to respond quickly to changes and make informed decisions on the fly.",
    backgroundColor: "#D8E2EC",
  },
  {
    icon: (
      <i
        className="fa-solid fa-bell"
        style={{ ...iconStyle, backgroundColor: "#6d6ac5" }}
      ></i>
    ),
    title: "Custom Alerts & Notifications",
    description:
      "Set up custom alerts to stay informed about critical events, ensuring timely actions and minimizing downtime.",
    backgroundColor: "#908DF2",
  },
];

const ProbePage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="probe-page">
      <Helmet>
        <title>Probe - Advanced Data Management Solutions</title>
        <meta
          name="description"
          content="Efficiently manage, visualize, and analyze your energy data with our state-of-the-art solutions designed to provide you with comprehensive insights and seamless integration."
        />
        <meta
          name="keywords"
          content="Energy Data Management, Automated Data Processing, In-Depth Analytics, Seamless Integration, Enhanced Security, Real-Time Monitoring, Custom Alerts"
        />
        <meta name="author" content="Ardra AI" />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="ProbePage - Advanced Data Management Solutions"
        />
        <meta
          property="og:description"
          content="Efficiently manage, visualize, and analyze your energy data with our state-of-the-art solutions designed to provide you with comprehensive insights and seamless integration."
        />
        <meta property="og:image" content={probeimage} />
        <meta property="og:url" content="https://ardra.ai/probepage" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://ardra.ai/probepage" />
      </Helmet>

      <header className="probe-header">
        <div className="probe-header-content">
          <h1 className="probe-main-title">
            ardraProbe : Advanced Data Management
          </h1>
          <h2 className="probe-subtitle">
            Revolutionize Your Energy Data Handling
          </h2>
          <p className="probe-header-description">
            Efficiently manage, visualize, and analyze your energy data with our
            state-of-the-art solutions designed to provide you with
            comprehensive insights and seamless integration.
          </p>
        </div>
      </header>

      <section className="probe-content">
        <div className="probe-intro">
          <h2 className="probe-title">Transformative Solutions</h2>
          <p className="probe-description">
            Discover the power of our advanced data management system, crafted
            to provide exceptional insights and streamline your energy data
            workflows.
          </p>
        </div>

        <div className="card-container">
          {features.map((feature, index) => (
            <div
              className="feature-card1"
              key={index}
              style={{ backgroundColor: feature.backgroundColor }}
            >
              <div className="feature-content">
                <div className="probe-icon">{feature.icon}</div>
                <h3 className="probe-feature-title">{feature.title}</h3>
                <p className="probe-feature-description">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="probe-why-choose-us">
        <div className="probe-why-content">
          <div className="probe-content-left">
            <img src={probeimage} alt="Probe" className="probe-image" />
          </div>
          <div className="probe-content-right">
            <h2 className="probe-why-title">Why Choose Us?</h2>
            <p className="probe-why-description">
              Our energy management system stands out for its reliability,
              scalability, and user-friendliness. With a commitment to
              innovation and customer satisfaction.
            </p>
          </div>
          <ul className="probe-why-list">
            <li>
              <i
                className="fa-solid fa-star fa-spin"
                style={{ color: "#28a745" }}
              ></i>
              &nbsp;<span style={{ fontWeight: "bold" }}>Expert Support:</span>{" "}
              Our dedicated team ensures you get the help you need, whenever you
              need it.
            </li>
            <li>
              <i
                className="fa-solid fa-star fa-spin"
                style={{ color: "#28a745" }}
              ></i>
              &nbsp;
              <span style={{ fontWeight: "bold" }}>Custom Solutions:</span>{" "}
              Tailored solutions that fit your specific needs and goals.
            </li>
            <li>
              <i
                className="fa-solid fa-star fa-spin"
                style={{ color: "#28a745" }}
              ></i>
              &nbsp;<span style={{ fontWeight: "bold" }}>Proven Results:</span>{" "}
              Track record of successful implementations and satisfied clients.
            </li>
            <li>
              <i
                className="fa-solid fa-star fa-spin"
                style={{ color: "#28a745" }}
              ></i>
              &nbsp;
              <span style={{ fontWeight: "bold" }}>
                Continuous Improvement:
              </span>{" "}
              We keep evolving to bring you the latest advancements in energy
              management technology.
            </li>
          </ul>
        </div>
      </section>

      <section className="probe-cta">
        <div className="probe-cta-content">
          <h2 className="probe-cta-title">
            Transform Your Data Management Today
          </h2>
          <p className="probe-cta-description">
            Discover how our advanced data management solutions can
            revolutionize your processes. Book a demo now to explore the
            powerful features we offer.
          </p>
          <div className="probe-cta-actions">
            <a
              href="https://calendly.com/pranjal-2diz/ardra-ai-socials"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button"
            >
              Book a Demo
            </a>
            <p className="probe-cta-testimonial">
              "Our productivity has soared since we implemented this system.
              Highly recommend it!" - <strong>Jane Doe, CTO</strong>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProbePage;
