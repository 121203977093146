import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./themeToggle.css";

const ThemeToggle = () => {
  // Set dark mode as default
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setDarkMode(savedTheme === "dark");
    } else {
      // Default to dark theme if no saved theme is found
      localStorage.setItem("theme", "dark");
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = darkMode ? "light" : "dark";
    setDarkMode(!darkMode);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <>
      <Helmet>
        <body className={darkMode ? "dark-mode" : "light-mode"} />
      </Helmet>
      <div className="theme-toggle">
        <input
          type="checkbox"
          id="theme-toggle-checkbox"
          checked={darkMode}
          onChange={toggleTheme}
        />
        <label htmlFor="theme-toggle-checkbox" className="theme-toggle-label">
          <span
            className={`toggle-icon moon ${darkMode ? "show" : "hide"}`}
            style={{
              color: "#ffffff",
            }}
          >
            ☀
          </span>
          <span className={`toggle-icon sun ${darkMode ? "hide" : "show"}`}>
            <i
              className="fa-solid fa-moon fa-xl"
              style={{ color: "#000000" }}
            ></i>
          </span>
        </label>
      </div>
    </>
  );
};

export default ThemeToggle;
