import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ardralogonav from "../Assist/navbarLogo.png";
import "./navbar.css";
import ThemeToggle from "./ThemeToggle/themeToggle";
import USAFlag from "../Assist/landingfeatureassist/Flag-United-States-of-America.webp";

function Navbar() {
  const navRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(prev => !prev);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navRef]);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = navRef.current;
      if (navbar) {
        if (window.scrollY > 0) {
          navbar.classList.add("navbar-scrolled");
        } else {
          navbar.classList.remove("navbar-scrolled");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className="navbar" ref={navRef}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src={ardralogonav} className="logo-img" alt="Ardra AI" />
        </Link>

        {/* Navigation Menu */}
        <div className={`navbar-collapse ${isMenuOpen ? "show" : ""}`}>
          <ul className="navbar-menu">
            <li className="navbar-item">
              <a href="#" className="navbar-link">
                Product <i className="fa fa-chevron-down dropdown-icon"></i>
              </a>
              <div className="dropdown-menu">
                <h5 className="dropdown-header">Smart Energy Solutions</h5>
                <Link
                  to="/emsPage"
                  className="dropdown-item"
                  onClick={handleMenuToggle}
                >
                  <i className="fa-solid fa-chart-bar fa-beat-fade"></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;ardraEMS
                </Link>
                <Link
                  to="/probe"
                  className="dropdown-item"
                  onClick={handleMenuToggle}
                >
                  <i className="fa-solid fa-network-wired fa-beat-fade"></i>
                  &nbsp;&nbsp;&nbsp;ardraPROBE
                </Link>
                <Link
                  to="/energyGPTPage"
                  className="dropdown-item"
                  onClick={handleMenuToggle}
                >
                  <i className="fa-solid fa-brain fa-beat-fade"></i>
                  &nbsp;&nbsp;&nbsp; ardraGPT
                </Link>
              </div>
            </li>
            <li className="navbar-item">
              <a href="#" className="navbar-link">
                Pricing <i className="fa fa-chevron-down dropdown-icon"></i>
              </a>
              <div className="dropdown-menu">
                <h5 className="dropdown-header">Contact Sales</h5>
                <a
                  href="https://calendly.com/pranjal-2diz/ardra-ai-socials"
                  className="dropdown-item"
                  target="_blank"
                  onClick={handleMenuToggle}
                >
                  <i className="fa-solid fa-phone fa-shake"></i>
                  &nbsp;&nbsp;&nbsp;Contact Us
                </a>
              </div>
            </li>

            <li className="navbar-item">
              <a href="#" className="navbar-link">
                Resource <i className="fa fa-chevron-down dropdown-icon"></i>
              </a>
              <div className="dropdown-menu">
                <Link
                  to="/document"
                  className="dropdown-item"
                  onClick={handleMenuToggle}
                >
                  <i class="fa-solid fa-book-open-reader fa-beat-fade"></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;Documentation
                </Link>
                <Link
                  to="/blog"
                  className="dropdown-item"
                  onClick={handleMenuToggle}
                >
                  <i class="fa-solid fa-file-pen fa-beat-fade"></i>
                  &nbsp;&nbsp;&nbsp;Blog
                </Link>
              </div>
            </li>

            <li className="navbar-item">
              <Link to="/aboutUs" className="navbar-link">
                About Us
              </Link>
            </li>
          </ul>
          <ul className="navbar-actions">
            <li className="navbar-item">
              <Link
                className="navbar-button1"
                to="https://gpt.ardra.ai/"
                // to="http://app.ardracloud.com"
                target="_blank"
              >
                Sign In
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                className="navbar-button2"
                to="https://calendly.com/pranjal-2diz/ardra-ai-socials?month=2024-08"
                target="_blank"
              >
                Book a Demo
              </Link>
            </li>
            <img src={USAFlag} alt="USAFlag" />
          </ul>
        </div>
        <div className="navbar-right">
          <button
            className={`navbar-toggler ${isMenuOpen ? "active" : ""}`}
            onClick={handleMenuToggle}
          >
            ☰ {/* Hamburger Icon */}
          </button>
          <div className="theme-toggle-container">
            <ThemeToggle />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
