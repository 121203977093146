// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './Like_blog_mainPage.css';

// const LikeBlog = ({ blogId }) => {
//   const [likes, setLikes] = useState(0);
//   const [loading, setLoading] = useState(true);

// Fetch the initial like count on component mount
// useEffect(() => {
//   const fetchLikes = async () => {
//     try {
//       const response = await axios.get(`https://ardra-a-ibackend.vercel.app/api/blog/${blogId}/like-count`);
//       setLikes(response.data.likes);
//     } catch (error) {
//       console.error('Error fetching likes:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   fetchLikes();
// }, [blogId]);

// Handle the like button click
//   const handleLike = async () => {
//     try {
//       setLikes((prevLikes) => prevLikes + 1);

//       const response = await axios.post(`https://ardra-a-ibackend.vercel.app/api/blog/${blogId}/like`);
//       setLikes(response.data.likes);
//     } catch (error) {
//       console.error('Error liking the post:', error);

//       setLikes((prevLikes) => prevLikes - 1);
//     }
//   };

//   if (loading) return <div>Loading...</div>;

//   return (
//     <div className="like-container_mainPage">
//       <button onClick={handleLike} className="like-button_mainPage">
//         <i className="fa-solid fa-thumbs-up fa-lg" style={{ color: 'rgb(22,106,234' }} ></i>&nbsp;&nbsp;{likes}
//       </button>
//     </div>
//   );
// };

// export default LikeBlog;

// new

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Like_blog_mainPage.css";

const LikeBlog = ({ blogId }) => {
  const [likes, setLikes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasLiked, setHasLiked] = useState(false); // State to track if the user has already liked the blog
  const [showPopup, setShowPopup] = useState(false); // State for the popup message

  // Fetch the initial like count on component mount
  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const response = await axios.get(
          `https://ardra-a-ibackend.vercel.app/api/blog/${blogId}/like-count`
        );
        setLikes(response.data.likes);

        // Check if the user has already liked this blog
        const likedBlogs = JSON.parse(localStorage.getItem("likedBlogs")) || [];
        setHasLiked(likedBlogs.includes(blogId));
      } catch (error) {
        console.error("Error fetching likes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLikes();
  }, [blogId]);

  // Handle the like button click
  const handleLike = async () => {
    if (hasLiked) {
      setShowPopup(true); // Show the popup
      return;
    }

    try {
      const response = await axios.post(
        `https://ardra-a-ibackend.vercel.app/api/blog/${blogId}/like`
      );
      setLikes(response.data.likes);

      // Mark the blog as liked in localStorage
      const likedBlogs = JSON.parse(localStorage.getItem("likedBlogs")) || [];
      likedBlogs.push(blogId);
      localStorage.setItem("likedBlogs", JSON.stringify(likedBlogs));

      setHasLiked(true);
    } catch (error) {
      console.error("Error liking the post:", error);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="like-container_mainPage">
      <button onClick={handleLike} className="like-button_mainPage">
        <i
          className="fa-solid fa-thumbs-up fa-lg"
          style={{ color: "rgb(22,106,234" }}
        ></i>
        &nbsp;&nbsp;{likes}
      </button>

      {/* Custom Popup */}
      {showPopup && (
        <div className="popup">
          <p>You have already liked this blog.</p>
          <button onClick={() => setShowPopup(false)}>Close</button>
        </div>
      )}
    </div>
  );
};

export default LikeBlog;
