import React, { useState } from "react";
import "./share_blog.css";

const ShareBlog = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const currentUrl = window.location.href;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setAlertVisible(true);
        // Hide alert after 1.5 seconds and close the popup
        setTimeout(() => {
          setAlertVisible(false);
          setPopupOpen(false);
        }, 1500);
      })
      .catch(err => {
        console.error("Failed to copy link: ", err);
      });
  };

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  return (
    <div className="blog-container">

      {/* Share Button */}
      <div className="share-section">
        <button className="share-button" onClick={togglePopup}>
        <i class="fa-solid fa-share-from-square fa-lg"></i>
        </button>
        
      </div>

      {/* Popup */}
      {isPopupOpen && (
        <div className="share_popup">
          <div className="share_popup-content">
            <span className="close-button" onClick={togglePopup}>
              &times;
            </span>
            <h2>Share this blog</h2>
            <p>{currentUrl}</p>
            <button className="copy-button" onClick={copyToClipboard}>
              Copy URL
            </button>
          </div>
        </div>
      )}

      {/* Alert */}
      {alertVisible && <div className="alert">Link copied to clipboard!</div>}
    </div>
  );
};

export default ShareBlog;





